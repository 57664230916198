import { render, staticRenderFns } from "./SettingCurationTime.vue?vue&type=template&id=7271598e&scoped=true"
import script from "./SettingCurationTime.vue?vue&type=script&lang=js"
export * from "./SettingCurationTime.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/curation/settingcurationtime.css?vue&type=style&index=0&id=7271598e&prod&scoped=true&lang=css&external"
import style1 from "./SettingCurationTime.vue?vue&type=style&index=1&id=7271598e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7271598e",
  null
  
)

export default component.exports