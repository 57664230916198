<template>
  <div class="settingcuration_container">
    <div v-show="isDim" class="pc_dim"></div>
    <v-tour id="settingcuration" name="myTour" :steps="steps" :options="options" :callbacks="callbacks"></v-tour>
    <v-tour
      id="settingcuration_step2"
      name="step2Tour"
      :steps="steps2"
      :options="options2"
      :callbacks="callbacksStep2"
    ></v-tour>
    <v-tour
      id="settingcurationstep1_mobile"
      name="step1TourMo"
      :steps="steps1mobile"
      :options="mobileoptions"
      :callbacks="callbackMobile1"
    >
    </v-tour>
    <v-tour
      id="settingcurationstep2_mobile"
      name="step2TourMo"
      :steps="steps2mobile"
      :options="mobileoptions2"
      :callbacks="callbackMobile2"
    ></v-tour>

    <div class="settingcuration_container_wrap pc">
      <ul class="flex_space_between align_center settingcuration_title_wrap">
        <li>
          <h2 class="settingcuration_title">시간대별 큐레이션 설정</h2>
        </li>
        <li>
          <img class="cursor" @click="onClickPre" src="/media/img/close_btn.png" alt="닫기" />
        </li>
      </ul>
      <div class="group" id="tutorialstep1" :class="{ active: isDim && isTutorial1 }">
        <ul class="settingcuration_daywrap">
          <li class="daylabel_wrap">
            <label class="daylabel">요일</label>
          </li>
          <li>
            <ul class="dp_flex">
              <li v-for="item in weekdata" :key="'day' + item.id" class="daybtn_wrap">
                <button class="daybtn" :class="{ active: selectday.includes(item.value) }" @click="onClickDay(item)">
                  {{ item.text }}
                </button>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="settingcuration_timewrap">
          <li class="daylabel_wrap">
            <label class="daylabel">시간</label>
          </li>
          <li class="dp_flex timeform">
            <span class="timeform_input cursor" @click="$emit('click-starttime')">{{ start }}</span>
            <span class="dp_flex align_center flex_justify_center wave">~</span>
            <span class="timeform_input cursor" @click="$emit('click-endtime')">{{ end }}</span>
          </li>
        </ul>
      </div>

      <div id="tutorialstep2" class="tutorialstep2" :class="{ active: isDim && isTutorial2 }">
        <ul class="settingcuration_settingwrap flex_space_between align_center">
          <li>
            <span class="settingcuration_setting_text">큐레이션 설정</span>
          </li>
          <li class="dp_flex align_center" @click="$emit('click-setcuration')">
            <button class="settingcuration_setting_text2">{{ datatype === 1 ? '변경' : '설정해주세요' }}</button>
            <img
              class="settingcuration_setting_arrow cursor"
              src="/media/img/curation/arrow_next_white.png"
              alt="설정화면 이동"
            />
          </li>
        </ul>
      </div>
      <ul class="dp_flex curation_list flex_wrap" v-if="datatype === 1">
        <li class="curation_item" v-if="curationtag.target">{{ curationtag.target.join(', ') }}</li>
        <li class="curation_item" v-if="curationtag.domesticRate">국내 {{ curationtag.domesticRate }}%</li>
        <li class="curation_item" v-if="genreTotal">{{ genreTotal.join(', ') }}</li>
        <li class="curation_item" v-if="curationtag.period">
          {{ curationtag.period.map(item => `${item}s`).join(', ') }}
        </li>
      </ul>
      <ul class="dp_flex flex_end curation_delete_wrap" v-if="datatype !== -1">
        <li>
          <a class="curation_delete cursor" @click="$emit('delete-curation')">삭제</a>
        </li>

        <li class="confirm_btn_wrap">
          <button class="confirm_btn" @click="$emit('click-apply')" :disabled="btnDisabled">
            {{ btnText }}
          </button>
        </li>
      </ul>
    </div>
    <div class="settingcuration_container_wrap mo">
      <div v-show="isDim" class="dim"></div>
      <mocuration-header @clickpre="onClickPre">
        <template #mocurationtitle>
          시간대별 큐레이션 설정
        </template>
      </mocuration-header>
      <main class="settingcuration_body">
        <div class="group">
          <ul class="settingcuration_daywrap" :class="{ active: isDim && isTutorial1 }">
            <li class="daylabel_wrap">
              <label class="daylabel">요일</label>
            </li>
            <li>
              <ul class="dp_flex">
                <li v-for="item in weekdata" :key="'day' + item.id" class="daybtn_wrap">
                  <button class="daybtn" :class="{ active: selectday.includes(item.value) }" @click="onClickDay(item)">
                    {{ item.text }}
                  </button>
                </li>
              </ul>
            </li>
          </ul>
          <ul
            id="tutorialstep1_mo"
            class="dp_flex flex_column settingcuration_timewrap_mo"
            :class="{ active: isDim && isTutorial1 }"
          >
            <li class="flex_space_between align_center">
              <label class="time_label_mo">시작 시간</label>
              <div class="time_wrap_mo dp_flex flex_space_between" @click="$emit('click-starttime')">
                <span>{{ start }}</span>
                <figure class="time_setting_img_wrap align_center">
                  <img class="time_setting_img" src="/media/img/curation/arrow_next_white.png" alt="시간설정하기" />
                </figure>
              </div>
            </li>
            <li class="flex_space_between align_center time_endtime_mo">
              <label class="time_label_mo">종료 시간</label>
              <div class="time_wrap_mo dp_flex flex_space_between" @click="$emit('click-endtime')">
                <span>{{ end }}</span>
                <figure class="time_setting_img_wrap align_center">
                  <img class="time_setting_img" src="/media/img/curation/arrow_next_white.png" alt="시간설정하기" />
                </figure>
              </div>
            </li>
          </ul>
          <ul
            id="tutorialstep2_mo"
            :class="{ active: isDim && isTutorial2 }"
            class="settingcuration_settingwrap_mo flex_space_between"
          >
            <li>
              <span class="settingcuration_setting_text">큐레이션 설정</span>
            </li>
            <li class="flex_space_between align_center cursor" @click="$emit('click-setcuration')">
              <button class="settingcuration_setting_text2">설정해주세요</button>
              <img
                class="settingcuration_setting_arrow cursor"
                src="/media/img/curation/arrow_next_white.png"
                alt="설정화면 이동"
              />
            </li>
          </ul>
          <ul class="flex_end curation_delete_wrap" v-if="datatype !== -1">
            <li>
              <a class="curation_delete cursor" @click="$emit('delete-curation')">삭제</a>
            </li>
          </ul>
        </div>
      </main>
      <footer class="settingcuration_footer">
        <button class="settingcuration_confirmbtn" @click="$emit('click-apply')" :disabled="btnDisabled">
          {{ btnText }}
        </button>
      </footer>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SettingCurationTime',
  emits: [
    'clickday',
    'click-starttime',
    'click-endtime',
    'click-setcuration',
    'delete-curation',
    'click-apply',
    'click-closecurationtime'
  ],
  props: {
    start: {
      type: String,
      default: '09:00',
      required: false
    },
    end: {
      type: String,
      default: '09:00',
      required: false
    },
    selectday: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    datatype: {
      type: Number,
      required: false,
      default: -1
    },
    curationtag: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    isbtndisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    curationinfo: {
      type: Object,
      required: false,
      default: () => {
        return null;
      }
    }
  },
  components: {
    'mocuration-header': () => import(`@/components/curation/MoCurationHeader.vue`)
  },
  data() {
    return {
      weekdata: [
        { id: 1, value: 1, text: '월' },
        { id: 2, value: 2, text: '화' },
        { id: 3, value: 3, text: '수' },
        { id: 4, value: 4, text: '목' },
        { id: 5, value: 5, text: '금' },
        { id: 6, value: 6, text: '토' },
        { id: 7, value: 7, text: '일' }
      ],
      options: {
        labels: {
          buttonSkip: '',
          buttonPrevious: '',
          buttonNext: '다음',
          buttonStop: '다음'
        }
      },
      options2: {
        labels: {
          buttonSkip: '',
          buttonPrevious: '',
          buttonNext: '',
          buttonStop: '완료'
        }
      },
      mobileoptions: {
        labels: { buttonSkip: '', buttonPrevious: '', buttonNext: '', buttonStop: '다음' }
      },
      mobileoptions2: {
        labels: { buttonSkip: '', buttonPrevious: '', buttonNext: '', buttonStop: '완료' }
      },
      steps: [
        {
          target: '#tutorialstep1',
          content: `먼저, 큐레이션을 적용할 시간대와 요일을 설정해주세요.`,
          params: {
            placement: 'bottom'
          }
        }
      ],
      steps2: [
        {
          target: '#tutorialstep2',
          content: `마지막으로, 설정된 시간과 요일에 적용할 큐레이션을 설정해주세요. 음악 큐레이션을 설정해주세요.`,
          params: {
            placement: 'bottom'
          }
        }
      ],
      steps1mobile: [
        {
          target: '#tutorialstep1_mo',
          content: `먼저, 큐레이션을 적용할 시간대와 요일을 설정해주세요.`,
          params: {
            placement: 'bottom'
          }
        }
      ],
      steps2mobile: [
        {
          target: '#tutorialstep2_mo',
          content: `마지막으로, 설정된 시간과 요일에 적용할 큐레이션을 설정해주세요.`,
          params: {
            placement: 'bottom'
          }
        }
      ],

      isDim: false,
      isTutorial1: false,
      isTutorial2: false,
      callbacks: {
        onFinish: this.onFinishStep1
      },
      callbacksStep2: {
        onFinish: this.onFinishStep2
      },
      callbackMobile1: {
        onFinish: this.onClicmMobileDim
      },
      callbackMobile2: {
        onFinish: this.onClicmMobileDim2
      },
      timeCurationStep2: localStorage.getItem('timeCurationStep2') ? localStorage.getItem('timeCurationStep2') : false
    };
  },

  mounted() {
    if (!this.timeCurationStep2) {
      this.isDim = true;
      this.$tours.myTour.start();
      this.$tours.step1TourMo.start();
      this.isTutorial1 = true;
    }
  },

  methods: {
    onClicmMobileDim2() {
      if (this.isTutorial2) {
        this.isTutorial2 = false;
        this.$tours.step2TourMo.finish();
        this.isDim = false;
        this.$router.push({ name: 'PlayerView', params: { openCuration: true, osType: 'mobile' } }).catch(() => {});
        localStorage.setItem('timeCurationStep2', true);

        return false;
      }
    },
    onClicmMobileDim() {
      if (this.isTutorial1) {
        this.isTutorial1 = false;
        this.isTutorial2 = true;
        this.$tours.step1TourMo.finish();
        this.$tours.step2TourMo.start();
        return false;
      }
    },
    onClickPre() {
      this.$emit('click-closecurationtime');
    },
    onClickDay(item) {
      this.$emit('clickday', item);
    },
    onFinishStep1() {
      this.isTutorial1 = false;
      this.isTutorial2 = true;
      this.$tours.step2Tour.start();
    },
    onFinishStep2() {
      this.isTutorial2 = false;
      this.isDim = false;
      this.$router.push({ name: 'PlayerView', params: { openCuration: true, osType: 'pc' } }).catch(() => {});
      const timeCurationStep1 = localStorage.getItem('timeCurationStep1');
      if (timeCurationStep1) {
        localStorage.setItem('timeCurationStep2', true);
      }
    }
  },
  computed: {
    btnDisabled() {
      if (this.curationinfo) {
        const { target, period, color, genre, genreTuneN, genreTuneY, material } = this.curationinfo;

        let totalGenre = [];

        if (genre) {
          totalGenre = [...totalGenre, ...genre];
        }

        if (genreTuneN) {
          if (Array.isArray(genreTuneN)) {
            totalGenre = [...totalGenre, ...genreTuneN];
          } else {
            totalGenre = [...totalGenre, ...genreTuneN.split(',')];
          }
        }

        if (genreTuneY) {
          if (Array.isArray(genreTuneY)) {
            totalGenre = [...totalGenre, ...genreTuneY];
          } else {
            totalGenre = [...totalGenre, ...genreTuneY.split(',')];
          }
        }
        if (!this.isbtndisabled) {
          if (
            this.selectday.length > 0 &&
            target &&
            target.length > 0 &&
            period &&
            period.length > 0 &&
            color &&
            color !== -1 &&
            totalGenre.length > 0 &&
            material &&
            material.length > 0 &&
            material.length < 4
          ) {
            return false;
          }
          return true;
        }
      } else {
        return true;
      }

      return this.isbtndisabled;
    },
    genreTotal() {
      let genreTotalList = [];
      if (this.curationtag.genre) {
        genreTotalList = [...this.curationtag.genre];
      }

      if (this.curationtag.genreTuneN) {
        genreTotalList = [...genreTotalList, ...this.curationtag.genreTuneN];
      }

      if (this.curationtag.genreTuneY) {
        genreTotalList = [...genreTotalList, ...this.curationtag.genreTuneY];
      }

      return genreTotalList;
    },
    btnText() {
      if (this.curationid) {
        return '적용';
      }
      return '완료';
    }
  }
});
</script>
<style scoped src="@/assets/css/curation/settingcurationtime.css"></style>
<style>
#settingcuration > .v-step,
#settingcuration_step2 > .v-step,
#settingcurationstep1_mobile > .v-step,
#settingcurationstep2_mobile > .v-step {
  background-color: var(--whitefff);
  max-width: 331px;
  width: 100%;
  color: var(--000000);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  border-radius: 12px;
}

#settingcuration .v-step__content {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
}

#settingcuration > .v-step {
  max-width: 399px;
  text-align: center;
  padding: 16px 21px;
}

#settingcurationstep1_mobile > .v-step {
  display: none;
}

#settingcuration .v-step__buttons,
#settingcuration_step2 .v-step__buttons,
#settingcurationstep1_mobile .v-step__buttons,
#settingcurationstep2_mobile .v-step__buttons {
  display: flex;
  justify-content: flex-end;
}

#settingcuration .v-step__button,
#settingcuration_step2 .v-step__button,
#settingcurationstep1_mobile .v-step__button,
#settingcurationstep2_mobile .v-step__button {
  color: var(--000000);
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  padding-top: 0;
}

#settingcuration .v-step__button,
#settingcuration_step2 .v-step__button {
  font-size: 16px;
  line-height: 23px;
  color: var(--000000);
  text-decoration: underline;
  padding: 0;
  margin: 0;
}

#settingcurationstep1_mobile .v-step__button,
#settingcurationstep2_mobile .v-step__button {
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}

/* settingcuration_step2 */
#settingcuration_step2 > .v-step {
  max-width: 424px;
  padding: 12px 20px;
}

#settingcuration_step2 .v-step__content {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 20px;
}

@media all and (max-width: 768px) {
  #settingcuration > .v-step,
  #settingcuration_step2 > .v-step {
    display: none;
  }

  #settingcurationstep1_mobile > .v-step {
    display: inline-block;
    padding: 12px 16px;
  }

  .settingcurationstep2_mobile > .v-step {
    max-width: 294px;
    text-align: center;
  }

  #settingcurationstep1_mobile .v-step__content,
  #settingcurationstep2_mobile .v-step__content {
    margin-bottom: 0;
  }
  #settingcurationstep1_mobile .v-step__buttons {
    display: flex;
    justify-content: center;
  }
}
</style>
